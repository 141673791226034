import React from "react";
import { Controller, useFormContext } from "react-hook-form";
import {
  Box,
  Typography,
  InputBase,
  styled,
  FormControl,
  InputLabel,
} from "@mui/material";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";

const StyledInputBox = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center", // Center content vertically
  justifyContent: "center", // Center content horizontally
  border: `1px solid ${theme.palette.grey[400]}`,
  borderRadius: theme.shape.borderRadius,
  padding: theme.spacing(1.5),
  cursor: "pointer",
  "&:hover": {
    borderColor: theme.palette.primary.main,
    backgroundColor: theme.palette.action.hover,
  },
  "&.Mui-focused": {
    borderColor: theme.palette.primary.main,
  },
}));

export default function RHFFileInputBox({
  name,
  label,
  placeholder = "Upload",
  ...other
}) {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <FormControl fullWidth >
          {label && <Typography variant="body1">{label}</Typography>}
          <input
            id={name}
            type="file"
            accept="image/*"
            hidden
            onChange={(e) => {
              const file = e.target.files[0];
              field.onChange(file); // Updates React Hook Form value
            }}
            {...other}
          />
          <StyledInputBox
            onClick={() => document.getElementById(name)?.click()}
            className={field.value ? "Mui-focused" : ""}
            sx={{
              display: "flex", // Ensure it's a flex container
              justifyContent: "center", // Center horizontally
              alignItems: "center", // Center vertically
              border: "1.5px dashed #bdbdbd",
              //   padding: "8px",
            }}
          >
            <InsertDriveFileIcon
              sx={{
                color: field.value ? "primary.main" : "text.secondary",
                paddingLeft: 1,
              }}
            />
            <InputBase
              value={field.value?.name || ""}
              placeholder={placeholder}
              readOnly
              fullWidth
              sx={{
                textAlign: "center", // Center text horizontally
                paddingLeft: 1, // Optional: Add padding to the left for spacing between the icon and text
                width: field.value?.name ? "100%" : "68px",
                flex: "none",
                paddingRight: 1,
              }}
            />
          </StyledInputBox>
          {error && (
            <Typography color="error" variant="body2" sx={{ mt: 1 }}>
              {error.message}
            </Typography>
          )}
        </FormControl>
      )}
    />
  );
}
