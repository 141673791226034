// theme.js or where you define your MUI theme
import { createTheme } from "@mui/material/styles";

const theme = createTheme({
  typography: {
    fontFamily: "Plus Jakarta Sans",
    lineHeight: 1.3,
    h1: {
      fontSize: "3.2rem",
      fontWeight: 600,
      color: "#212B36",
    },
    h2: {
      fontSize: "2.2rem",
      fontWeight: 700,
      color: "#212B36",
    },
    h3: {
      color: "#212B36",
    },
    h6: {
      fontWeight: 700,
      color: "#212B36",
    },
  },
});

export default theme;
