import React from "react";
import { Container, Box, Typography } from "@mui/material";
import ArrowOutwardIcon from "@mui/icons-material/ArrowOutward";

import Button from "../../../components/Button";
import SignUpCommonDialog from "../../../components/singUpForm/SignUpCommonDialog";

const CallToAction = () => {
  const [open, setOpen] = React.useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  return (
    <>
      <Box component="section" className="call-to-action" mb={5}>
        <Container>
          <div className="call-action-content">
            <Typography variant="body1">
              Experience TaskBoard’s Freedom to Scale Say goodbye to per-user
              costs and hello to a simpler, more predictable way to manage
              projects. Join TaskBoard today and get powerful project management
              at one fixed price.
            </Typography>
            <SignUpCommonDialog
              title={"Start Your Free Trial"}
              className={"custom-btn"}
            >
              <ArrowOutwardIcon />
            </SignUpCommonDialog>
          </div>
        </Container>
      </Box>
    </>
  );
};
export default CallToAction;
