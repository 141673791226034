import {
  createBrowserRouter,
  createRoutesFromElements,
  Routes,
  Route,
} from "react-router-dom";
import App from "../App";
import { Sections } from "../pages/home";
import BlogPage from "../pages/Blog/Blog";
import TermsConditions from "../pages/terms-and-conditions/TermsConditions";
import PrivacyPolicy from "../pages/privacy-policy/PrivacyPolicy";

export const route = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    children: [
      {
        path: "",
        element: <Sections />,
      },
      {
        path: "blog",
        element: <BlogPage />,
      },
      {
        path: "terms",
        element: <TermsConditions />,
      },
      {
        path: "privacy-policy",
        element: <PrivacyPolicy />,
      },
    ],
  },
]);
