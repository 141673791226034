import axios from "axios";

export const createTenant = async (data) => {
  return await axios.post(`${process.env.REACT_APP_BASE_URL}`, data, {
    headers: { "Content-Type": "application/x-www-form-urlencoded" },
  });
};

export const checkDomain = async (data) => {
  return await axios.get(
    `${process.env.REACT_APP_BASE_URL}/check_tenant?domain=${
      data?.domain || ""
    }`
  );
};

export const getDemo = async (data) => {
  return await axios.get(
    `${process.env.REACT_APP_BASE_URL}/get_demo_taskboard?company_name=${
      data?.company_name
    }&email=${encodeURIComponent(data?.email)}`
  );
};
