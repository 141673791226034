import { Typography } from "@mui/material";
import React from "react";
import CheckIcon from "../../assets/images/check-mark-icon.png";

function ThankYou({ data }) {
  console.log('thank',data)
  const {responseData: tenantInfo ={}} = data.data;
  return (
    <div className="thankyou-popup">
      <div className="thankyou-check-icon">
        <img src={CheckIcon} alt="" width={"100px"} height={"100px"} />
      </div>
      <Typography variant="h2" mb={2}>
        Thank you for signing up for TaskBoard!
      </Typography>
      <Typography variant="body1" mb={2.5}>
        An email with instructions on how to log in to TaskBoard along with the
        credentials have been sent to the email address below:
      </Typography>
      <ul>
        <li>
          <strong>Email:</strong> {tenantInfo?.email}
        </li>
        {/* <li>
          <strong>Temporary Password:</strong> {tenantInfo?.temporary_password}
        </li> */}
        <li>
          <strong>Login URL:</strong>{" "}
          <a href={"https://" + tenantInfo?.login_url} target="_blank">
            {tenantInfo?.login_url}
          </a>
        </li>
      </ul>
      <Typography variant="body1" mb={2}>
        Check your inbox for full details and instructions. For help, contact us
        at <a href="mailto: support@taskboard.dev">support@taskboard.dev</a>.
      </Typography>
      <Typography variant="body1">Welcome aboard!</Typography>
    </div>
  );
}

export default ThankYou;
