import * as React from "react";
import {
  Dialog,
  DialogContent,
  Slide,
  useMediaQuery,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";

import DemoForm from "./DemoForm";

function useModalTransaction(open) {
  const [transactionDone, setTransactionDone] = React.useState(false);


  React.useEffect(() => {
    if (open && !transactionDone) {
      console.log("Transaction executed");
      setTransactionDone(true);
    }
  }, [open, transactionDone]);

  const resetTransaction = () => setTransactionDone(false);

  return { resetTransaction };
}

export default function SeeDemoComp({ open, handleClose, setThankYou }) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const { resetTransaction } = useModalTransaction(open);

  const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });

  const onClose = () => {
    resetTransaction();
    handleClose();
  };

  return (
    <React.Fragment>
      <Dialog
        fullScreen={fullScreen}
        open={open}
        onClose={onClose}
        aria-labelledby="responsive-dialog-title"
        TransitionComponent={Transition}
        keepMounted
        className={`seeDemo-popup ${
          fullScreen ? "mobile-view" : "desktop-view"
        }`}
        sx={{
          "& .MuiDialog-paper": fullScreen
            ? {
                margin: 0,
                borderRadius: 0,
                height: "100vh",
              }
            : {
                maxWidth: "800px",
                borderRadius: "12px",
              },
        }}
      >
        <DialogContent sx={{ p: 0 }}>
          <DemoForm
            handleClose={handleClose}
            setThankYou={setThankYou}
            onClose={onClose}
            fullScreen={fullScreen}
          />
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
}
