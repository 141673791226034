import React from "react";
import { Container, Box, Stack, Typography } from "@mui/material";

const BlogPage = () => {
  return (
    <>
      <Box component="section" className="blog-content" py={5}>
        <Container>
          <Typography variant="h2" mb={3}>
            The TaskBoard Story: A Journey from In-House Solution to Industry
            Game-Changer
          </Typography>
          <Typography variant="body1" mb={2}>
            {" "}
            When we first created TaskBoard, it was never meant to be anything
            more than an internal tool. I remember the day clearly—I was
            frustrated with the limitations of existing project management
            software. High per-user fees made it impractical to onboard everyone
            we needed, especially short-term contractors or clients, and the
            complex setup didn’t make it any easier. Our workflows were
            stalling, and we needed something lean, fast, and intuitive that
            would actually support our team’s needs.
          </Typography>
          <Typography variant="body1" mb={2}>
            {" "}
            So, we built our own solution. The first version of TaskBoard was as
            simple as it gets: a chat-style interface that showed projects on
            the left and tasks on the right. It was quick, responsive, and
            designed for people who just wanted to get work done. Creating a
            task felt as easy as sending a message—click the board, create the
            task, hit enter, and it was done. Moving tasks around was a breeze
            with drag-and-drop functionality, and most importantly, there was no
            lag. We could feel the difference immediately. This wasn’t just
            another task tracker; it was a system that fit the way we actually
            worked.
          </Typography>
          <Typography variant="body1" mb={2}>
            As we used it, we began adding features to solve our own workflow
            pain points. One of the biggest challenges was tracking hours on
            tasks. We implemented a worklog feature where everyone could log
            their daily hours, making it easy to see where time was being spent.
            Soon, we had a clear picture of our team’s productivity, and it was
            easier to balance workloads across projects. I can’t tell you how
            much this feature helped us optimize our resource allocation. We
            started grouping people into teams and could quickly see who was
            overbooked and who had some bandwidth, letting us move resources
            where they were most needed.
          </Typography>
          <Typography variant="body1" mb={2}>
            At that point, a few friends and family companies started using
            TaskBoard as well. Their feedback was overwhelmingly positive, and
            we saw potential to share TaskBoard with a wider audience. But
            here’s where things got complicated. Initially, we thought we could
            offer TaskBoard as a downloadable product, something companies could
            install and run themselves. But it turned out that setup and ongoing
            maintenance were significant obstacles. So we shifted to a fully
            hosted model, which gave clients full access to all features without
            the overhead of software management, support, or per-user fees. This
            change simplified everything—clients got the power of TaskBoard
            without any hassle, and our model became much more scalable.
          </Typography>
          <Typography variant="body1" mb={2}>
            As TaskBoard matured, we recognized the need for tracking projects
            by milestones rather than rigid two-week sprints. We added
            Milestones as a way to group tasks with progress bars, estimated
            hours, and checkpoints. With Milestones, we could see exactly how a
            project was progressing, down to which tasks were taking longer than
            planned. Once a Milestone was locked, it couldn’t be changed,
            bringing a level of accountability that transformed the way we
            worked. Everyone felt responsible for delivering on time, and
            clients knew exactly what to expect. We even added a buffer for bug
            fixes and feedback, which kept unforeseen issues from derailing our
            release schedules.
          </Typography>
          <Typography variant="body1" mb={2}>
            Another game-changer for us was integrating billing into the
            worklogs. By tagging tasks as billable or non-billable, we could
            accurately track hours and generate invoices that reflected the real
            work done. Before this, tracking and billing hours was chaotic, and
            we often missed out on billable hours. TaskBoard streamlined this
            process, letting us bill clients with transparency and accuracy
          </Typography>
          <Typography variant="body1" mb={2}>
            Looking back, TaskBoard was born out of a need for something
            practical, user-friendly, and efficient. It’s been incredible to see
            how a tool we initially built just to make our own lives easier has
            evolved into a comprehensive project management platform that serves
            other companies just as well. We’ve kept it simple, accessible, and
            powerful, without the per-user fees or hidden costs.
          </Typography>
          <Typography variant="body1" mb={2}>
            Today, TaskBoard has become the go-to platform for teams who want a
            streamlined, no-nonsense approach to project management. It’s more
            than just software; it’s a system built by people who understand the
            daily grind, the urgency of deadlines, and the value of
            accountability. That’s the TaskBoard story, and we’re excited to see
            where it goes next.
          </Typography>
        </Container>
      </Box>
    </>
  );
};

export default BlogPage;
