import * as yup from "yup";

export const signUpSchema = yup.object({
  company_name: yup
    .string()
    .label("Company Name")
    .required("Company name is required"),
  domain: yup
    .string()
    .label("Domain")
    // .matches(/^[^.]*$/, "Domain must not contain a dot (.)")
    .required("Domain is required"),
  email: yup
    .string()
    .label("Email")
    .email("Invalid email address")
    .required("Email is required"),
  // logo: yup
  //   .mixed()
  //   .nullable() // Allows null or undefined values
  //   .notRequired() // Makes the field optional
  //   .test("fileType", "Unsupported file format", (value) => {
  //     if (!value) return true; // Skip validation if no file is provided
  //      console.log(value);
  //     return ["image/jpeg", "image/png"].includes(value.type);
  //   })
  //   .test("fileSize", "File size exceeds 50KB", (value) => {
  //     if (!value) return true; // Skip validation if no file is provided
  //     return value.size <= 50 * 1024;
  //   }),
  // favicon: yup
  //   .mixed()
  //   .nullable() // Allows null or undefined values
  //   .notRequired() // Makes the field optional
  //   .test("fileType", "Unsupported file format", (value) => {
  //     if (!value) return true; // Skip validation if no file is provided
  //     console.log(value)
  //     return ["image/jpeg", "image/png"].includes(value.type);
  //   })
  //   .test("fileSize", "File size exceeds 30KB", (value) => {
  //     if (!value) return true; // Skip validation if no file is provided
  //     return value.size <= 30 * 1024;
  //   }),
  plan_id: yup.string().label("Plan").required("Plan is required"),
  promo_code: yup.string().label("Promo Code"),

  address: yup
    .string()
    .label("Address")
    .when("plan_id", {
      is: "Free",
      then: (schema) => schema.optional().nullable(), // Optional for "Free"
      otherwise: (schema) => schema.required("Address is required"), // Required for other plans
    }),

  address_city: yup
    .string()
    .label("City")
    .when("plan_id", {
      is: "Free",
      then: (schema) => schema.optional().nullable(),
      otherwise: (schema) => schema.required("City is required"),
    }),

  address_state: yup
    .string()
    .label("State")
    .when("plan_id", {
      is: "Free",
      then: (schema) => schema.optional().nullable(),
      otherwise: (schema) => schema.required("State is required"),
    }),

  address_postal_code: yup
    .string()
    .label("Zip Code")
    .when("plan_id", {
      is: "Free",
      then: (schema) => schema.optional().nullable(),
      otherwise: (schema) => schema.required("Zip Code is required"),
    }),

  card_token: yup.string().label("Card Token").nullable(),

  accountHolderName: yup
    .string()
    .label("Account Holder Name")
    .when("plan_id", {
      is: "Free",
      then: (schema) => schema.optional().nullable(),
      otherwise: (schema) => schema.required("Name is required"),
    }),
});
