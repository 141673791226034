import React from "react";
import { Container, Box, Typography, Grid2 } from "@mui/material";
import AssignedTeamImage from "../../../assets/images/built-by-users.png";
import WorklogImage from "../../../assets/images/Billing-Worklog.png";
import MilestonesImage from "../../../assets/images/empower-your-team.png";
import BoardTrackingImage from "../../../assets/images/next-level-project-tracking.png";
import BoardUserImage from "../../../assets/images/user-new.png";

const whyChooseData = [
  {
    image: AssignedTeamImage,
    title: "Built by Users, Perfected for Teams",
    content: [
      "<ul class='why-choose-uline'><li>Created to solve real challenges</li><li>Streamlined project management with drag-and-drop updates</li><li>Quick navigation and zero lag for efficiency</li><li>Customizable workflows for different teams</li><li>Smart accountability tools to keep teams on track</li></ul>",
    ],
  },
  {
    image: BoardTrackingImage,
    title: "Next Level Project Tracking",
    content: [
      "<ul class='why-choose-uline'><li><strong>Resource Accounting:</strong> Track time allocation and utilization</li><li><strong>Advanced Filtering:</strong> View tasks, teams, timelines, and budgets</li><li><strong>Extensive Reporting:</strong> Optimize resources and track progress</li><li><strong>Worklog System:</strong> Monitor hours, balance workloads, and identify resources</li></ul>",
    ],
  },
  {
    image: MilestonesImage,
    title: "Improved Team Productivity & Motivation",
    content: [
      "<ul class='why-choose-uline'><li>Clear time allocations</li><li>Visual milestone tracking</li><li>Real-time overrun alerts</li><li>Drives strategic results</li><li>Empowers teams to excel</li></ul>",
    ],
  },
  {
    image: WorklogImage,
    title: "Accurate tracking and transparency",
    content: [
      "<ul class='why-choose-uline'><li>Precise activity tracking</li><li>Tag tasks for simplicity</li><li>Addresses unlogged time gaps</li><li>Simplifies project reporting</li><li>Transparent progress tracking</li></ul>",
    ],
  },
  {
    image: BoardUserImage,
    title: "Unified Project Management Without Per-User Fees",
    content: [
      "<ul class='why-choose-uline'><li>No per-user fees, all-inclusive access</li><li>Full team collaboration in one platform</li><li>Integrated task tracking, time logging, and billing</li><li>Boosts accountability and efficiency</li><li>Keeps projects on time and on budget</li></ul>",
    ],
  },
];

const WhyChoose = () => {
  return (
    <Box component="section" className="why-choose-us" py={6}>
      <Container>
        <Grid2 container spacing={{ sm: 4, xs: 0 }}>
          <Grid2 size={{ sm: 6, xs: 12 }}>
            <Typography variant="h2" mb={1.5}>
              Modern Project Management, Redefined
            </Typography>
            <Typography variant="body1" mb={2}>
              TaskBoard offers everything you’d expect from a modern task
              tracking and project management system, but we take it several
              steps further. Built by actual users to address real-world
              challenges, TaskBoard was originally designed for in-house use.
              Its success and efficiency transformed it into a comprehensive
              solution for teams everywhere.
            </Typography>
          </Grid2>
          <Grid2 size={{ sm: 6, xs: 12 }}>
            <Typography variant="h6">
              Here’s what TaskBoard delivers:
            </Typography>
            <ul className="tb-delivers-list-ul">
              <li>
                <strong>Streamlined Task Management:</strong> Easily create,
                assign, and track tasks with an intuitive drag-and-drop
                interface.
              </li>
              <li>
                <strong>Customizable Workflows:</strong> Tailor processes for
                different teams, whether it's development, sales, or operations.
              </li>
              <li>
                <strong>Collaborative Tools:</strong> Keep cross-functional
                teams aligned with integrated communication and updates.
              </li>
            </ul>
          </Grid2>
        </Grid2>

        {whyChooseData.map((section, index) => (
          <Grid2
            container
            spacing={{ xs: 2, sm: 3, md: 6 }}
            alignItems="center"
            key={index}
            direction={index % 2 == 0 ? "row" : "row-reverse"}
            sx={{
              mt: { sm: 4, md: 6 },
            }}
          >
            <Grid2 size={{ xs: 12, md: 6 }}>
              <div className="why-choose-image">
                <img src={section.image} alt={section.title} />
              </div>
            </Grid2>
            <Grid2 size={{ xs: 12, md: 6 }}>
              <div className="why-choose-content">
                <Typography variant="h3" mb={1.5}>
                  {section.title}
                </Typography>
                {section.content.map((paragraph, idx) => (
                  <Typography variant="body1" key={idx} mb={2}>
                    <div dangerouslySetInnerHTML={{ __html: paragraph }}></div>
                  </Typography>
                ))}
              </div>
            </Grid2>
          </Grid2>
        ))}
      </Container>
    </Box>
  );
};

export default WhyChoose;
