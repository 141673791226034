import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Grid2,
  Stack,
  Typography,
} from "@mui/material";
import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
} from "@stripe/react-stripe-js";
import React from "react";

import FormTextField from "../formElements/textfield";
import { LoadingButton } from "@mui/lab";

function CheckoutComp({ handleBack, isLoading }) {
  return (
    <Stack className="height100">
      <div className="steps_center-content">
        {/* <Typography variant="h6" mb={1.5} style={{ fontSize: 24 }}>
          Checkout
        </Typography> */}
        <Stack spacing={2} flexGrow={"1"}>
          <FormControl>
            <FormLabel id="demo-radio-buttons-group-label">
              <strong>Billing Details</strong>
            </FormLabel>
            <Grid2 container spacing={2} mt={1}>
              <Grid2 size={12}>
                <FormTextField
                  id="name"
                  name="address"
                  label="Address"
                  fullWidth
                />
              </Grid2>
              <Grid2 size={4}>
                <FormTextField
                  id="city"
                  name="address_city"
                  label="City"
                  fullWidth
                />
              </Grid2>
              <Grid2 size={4}>
                <FormTextField
                  id="state"
                  name="address_state"
                  label="State"
                  fullWidth
                />
              </Grid2>
              <Grid2 size={4}>
                <FormTextField
                  id="zipCode"
                  name="address_postal_code"
                  label="Zip Code"
                  fullWidth
                />
              </Grid2>
            </Grid2>
          </FormControl>
          <FormControl>
            <FormLabel id="demo-radio-buttons-group-label">
              <strong>Card Details</strong>
            </FormLabel>
            <Grid2 container spacing={2} mt={1}>
              <Grid2 size={12}>
                <Box sx={inputContainerStyle}>
                  <CardNumberElement
                    id="card-number-element"
                    options={OPTIONS}
                  />
                </Box>
              </Grid2>
              <Grid2 size={8}>
                <Box sx={inputContainerStyle}>
                  <CardExpiryElement />
                </Box>
              </Grid2>
              <Grid2 size={4}>
                <Box sx={inputContainerStyle}>
                  <CardCvcElement />
                </Box>
              </Grid2>
              <Grid2 size={12}>
                <FormTextField
                  id="accountHolderName"
                  name="accountHolderName"
                  placeholder="Card Holder Name"
                  type="text"
                  fullWidth
                />
              </Grid2>
            </Grid2>
          </FormControl>
        </Stack>
      </div>

      <Stack direction="row" justifyContent="flex-end">
        <Button variant="text" onClick={handleBack} sx={{ mr: 1 }} size="large">
          Back
        </Button>
        <LoadingButton
          loading={isLoading}
          variant="contained"
          type="submit"
          size="large"
        >
          Submit
        </LoadingButton>
      </Stack>
    </Stack>
  );
}

const inputContainerStyle = {
  padding: "16.5px 14px",
  border: "1px solid rgb(192 187 187)",
  borderRadius: "4px",
};

const OPTIONS = {
  showIcon: true,
  style: {
    base: {
      fontWeight: "500",
      fontFamily: "Roboto, Open Sans, Segoe UI, sans-serif",
      fontSmoothing: "antialiased",
      ":-webkit-autofill": {},
      "::placeholder": {},
    },
  },
};

export default CheckoutComp;
