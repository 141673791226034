import React from "react";
import { Dialog, DialogContent, DialogTitle, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

function CommonDialog({ children, open, handleClose, className }) {
  return (
    <Dialog
      //   fullScreen={isTablet} // Fullscreen for mobile and tablets
      //   fullWidth={isTablet} // FullWidth for better fit in these cases
      //   maxWidth={isTablet ? undefined : "md"}
      open={open}
      onClose={handleClose}
      aria-labelledby="responsive-dialog-title"
      className={className}
      //   TransitionComponent={Transition}
    >
      <DialogTitle className="close-popup" sx={{ padding: "6px" }}>
        <IconButton
          edge="start"
          color="inherit"
          onClick={handleClose}
          aria-label="close"
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent sx={{ p: 0 }}>{children}</DialogContent>
    </Dialog>
  );
}

export default CommonDialog;
