import React from "react";
import { Container, Box, Stack, Typography } from "@mui/material";
import ArrowOutwardIcon from "@mui/icons-material/ArrowOutward";

import Button from "../../../components/Button";
import SeeDemoComp from "../../../components/SeeDemo";
import SignUpCommonDialog from "../../../components/singUpForm/SignUpCommonDialog";
import CommonDialog from "../../../components/dialog/CommonDialog";
import ThankYouDemoDialog from "../../../components/SeeDemo/ThankYouDemoDialog";

const HeroBanner = () => {
  const [open, setOpen] = React.useState(false);
  const [thankYou, setThankYou] = React.useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  return (
    <>
      <Box component="section" className="hero-banner">
        <Container>
          <div className="hero-banner-content">
            <Typography variant="h1">
              Project management, simplified for teams of any size.
            </Typography>
            <Typography variant="body1">
              All the powerful features of top project management tools – at one
              fixed price, with no per-user fees.
            </Typography>

            <Stack direction="row" spacing={2} justifyContent="center">
              <SignUpCommonDialog
                title={"Get Started"}
                className={"custom-btn"}
              >
                <ArrowOutwardIcon />
              </SignUpCommonDialog>
              <Button
                buttonText={"See a Demo"}
                className={"custom-btn-outline"}
                handleClick={handleClickOpen}
              />
            </Stack>
          </div>
        </Container>
      </Box>
      {thankYou && (
        <CommonDialog
          open={thankYou}
          handleClose={() => setThankYou(false)}
          title={"Thankyou"}
          className="thankyou-popup"
        >
          <ThankYouDemoDialog />
        </CommonDialog>
      )}

      {open && (
        <SeeDemoComp
          open={open}
          handleClose={handleClose}
          setThankYou={setThankYou}
        />
      )}
    </>
  );
};
export default HeroBanner;
