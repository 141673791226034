import { ThemeProvider } from "@mui/material/styles";
import { Outlet } from "react-router-dom";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { Toaster } from "react-hot-toast";

import theme from "./theme";
import Footer from "./components/footer/index.js";
import "./assets/style.css";
import "./assets/responsive.css";
import Header from "./components/header/index.js";

const stripePromise = loadStripe(process.env.REACT_APP_PUBLIC_STRIPE_API_KEY);

function App() {
  return (
    <>
      <Elements stripe={stripePromise}>
        <ThemeProvider theme={theme}>
          <Toaster
            position="top-center"
            gutter={12}
            containerStyle={{ margin: "8px" }}
            toastOptions={{
              success: {
                duration: 3000,
              },
              error: {
                duration: 5000,
              },
              style: {
                fontSize: "16px",
                maxWidth: "500px",
                padding: "16px 24px",
                backgroundColor: "white",
                color: "black",
              },
            }}
          />
          <Header />
          <div className="body-wrapper">
            <Outlet />
          </div>
          <Footer />
        </ThemeProvider>
      </Elements>
    </>
  );
}

export default App;
