import Pricing from "./Pricing";
import HeroBanner from "./HeroBanner";
import Features from "./Features";
import WhyChoose from "./whyChoose";
import Testimonials from "./Testimonials";
import CallToAction from "./CallToAction";

const Sections = () => {
  return (
    <>
      <HeroBanner />
      <Features />
      <WhyChoose />
      <Pricing />
      <CallToAction />
      {/* <Testimonials /> */}
    </>
  );
};

export default Sections;
