import React, { useState } from "react";
import { Container, Box, Typography, Slider } from "@mui/material";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import CheckRoundedIcon from "@mui/icons-material/CheckRounded";
// import Slider from "@mui/material/Slider";

const Pricing = () => {
  const [selectedUser, setSelectedUser] = useState(50);

  function formatNumber(num) {
    return Number.isInteger(num) ? num.toString() : num.toFixed(2);
  }

  const features = [
    "Cost Per Month",
    "Kanban Boards",
    "Scrum Boards",
    "Custom Workflows",
    "Task Automation",
    "Time Tracking",
    "Real-time Reporting",
    "Project Timelines",
    "Bug Tracking",
    "Unlimited Users",
  ];

  const products = [
    {
      name: "TaskBoard",
      price: "$0.00",
      fixedCost: "$49.99/month $499.99/year",
      features: [
        "$49.99/month $499.99/year",
        true,
        true,
        true,
        true,
        true,
        true,
        true,
        true,
        true,
      ],
    },
    {
      name: "Jira",
      price: "Free (10 users), $7.75 - $15.25",
      fixedCost: "NA",
      features: [
        `$${formatNumber(selectedUser * 7.75)} - $${formatNumber(
          selectedUser * 15.25
        )}`,
        true,
        true,
        true,
        true,
        false,
        true,
        true,
        true,
        false,
      ],
    },
    {
      name: "Asana",
      price: "Free (10 Users), $10.99 - $24.99",
      fixedCost: "NA",
      features: [
        `$${formatNumber(selectedUser * 10.99)} - $${formatNumber(
          selectedUser * 24.99
        )}`,
        true,
        false,
        true,
        true,
        false,
        true,
        true,
        false,
        false,
      ],
    },
    {
      name: "Trello",
      price: "Free (10 Users) $5 - $10",
      fixedCost: "NA",
      features: [
        `$${formatNumber(selectedUser * 5)} - $${formatNumber(
          selectedUser * 10
        )}`,
        true,
        false,
        false,
        "limited",
        true,
        false,
        false,
        false,
        false,
      ],
    },
    {
      name: "Monday.com",
      price: "Free (2 users), $8 - $10",
      fixedCost: "NA",
      features: [
        `$${formatNumber(selectedUser * 8)} - $${formatNumber(
          selectedUser * 10
        )}`,
        true,
        false,
        true,
        true,
        true,
        true,
        true,
        false,
        false,
      ],
    },
  ];

  return (
    <>
      <Box component="section" className="pricing" id="pricing" py={6}>
        <Container>
          <Typography variant="h2" style={{ textAlign: "center" }} mb={4}>
            All the Tools You Need to Get Work Done
          </Typography>
          <div className="user_slider_range_cont">
            <Slider
              value={selectedUser}
              min={1}
              max={200}
              onChange={(event) => setSelectedUser(event.target.value)}
            />
            <p className="selected_user">
              Number of users -{" "}
              <span
                style={{
                  color: "rgb(27 62 97)",
                  fontSize: "18px",
                  fontWeight: 600,
                }}
              >
                {selectedUser}
              </span>
            </p>
          </div>

          <div className="feature-comparison">
            <div className="feature-header-row">
              <div className="cell feature-title">Feature</div>
              {products.map((product, index) => (
                <div key={index} className="cell product-title">
                  {product.name}
                </div>
              ))}
            </div>
            {features.map((feature, index) => (
              <div key={index} className="feature-td-row row">
                <div className="cell feature">{feature}</div>
                {products.map((product, productIndex) => (
                  <div key={productIndex} className="cell feature-check">
                    {product.features[index] === true ? (
                      <CheckRoundedIcon />
                    ) : (
                      product.features[index] || (
                        <CloseRoundedIcon className="feature-close" />
                      )
                    )}
                  </div>
                ))}
              </div>
            ))}
            {/* <div className="feature-td-row row">
              <div className="cell feature">Pricing (per user/month)</div>
              {products.map((product, index) => (
                <div key={index} className="cell feature-check">
                  {product.price}
                </div>
              ))}
            </div> */}

            {/* <div className="feature-td-row row ">
              <div className="cell feature">Fixed Monthly Cost</div>
              {products.map((product, index) => (
                <div key={index} className="cell feature-check">
                  {product.fixedCost}
                </div>
              ))}
            </div> */}
          </div>
        </Container>
      </Box>
    </>
  );
};
export default Pricing;
