import { Button, InputAdornment, Stack, Typography } from "@mui/material";
import React from "react";
import { useFormContext } from "react-hook-form";

import FormTextField from "../formElements/textfield";
import RHFFileInputButton from "../formElements/FileField";
import { checkDomain } from "../../services/createTenant";

function CompanyInfoComp({ activeStep, handleBack, setActiveStep }) {
  const { trigger, setError, clearErrors, getValues } = useFormContext();
  const domainUrl = window.location.hostname;

  const handleNextClick = async () => {
    const isValid = await trigger(["company_name", "domain", "email"]);
    if (isValid) {
      setActiveStep(activeStep + 1);
    }
  };

  const isAvailableDomain = async (e) => {
    const data = getValues();
    clearErrors("domain");
    if (!/^[a-zA-Z0-9-]+$/.test(data?.domain)) {
      setError("domain", {
        type: "manual",
        message: "Domain must not contain special characters.",
      });
    }

    if (data?.domain) {
      checkDomain(data)
        .then((res) => {
          const { duplicate_domain } = res.data.data.responseData;

          if (duplicate_domain) {
            setError("domain", {
              type: "manual",
              message: "The domain you selected is already taken.",
            });
          }
        })
        .catch((err) => {
          console.error(err);
        });
    }
  };

  return (
    <Stack className="height100">
      <div className="steps_center-content">
        <Typography variant="h6" mb={4} style={{ fontSize: 24 }}>
          Company Information
        </Typography>
        <Stack spacing={3} flexGrow={"1"}>
          <FormTextField name={"company_name"} label="Name" />
          <FormTextField
            name={"domain"}
            label="Domain"
            onBlur={isAvailableDomain}
            slotProps={{
              input: {
                endAdornment: (
                  <InputAdornment position="end" className="domain-static">
                    .{domainUrl}
                  </InputAdornment>
                ),
              },
            }}
          />
          <FormTextField name={"email"} label="Email Address" />
          {/* <RHFFileInputButton
          name={"logo"}
          label="Logo"
          type="file"
          slotProps={{ inputLabel: { shrink: true } }}
        />
        <RHFFileInputButton
          name={"favicon"}
          label="Fav Icon"
          type="file"
          slotProps={{ inputLabel: { shrink: true } }}
        /> */}
        </Stack>
      </div>

      <Stack direction="row" justifyContent="flex-end" className="">
        <Button
          variant="text"
          disabled={activeStep === 0}
          onClick={handleBack}
          sx={{ mr: 1 }}
          size="large"
        >
          Back
        </Button>
        <Button variant="contained" onClick={handleNextClick} size="large">
          {"Next"}
        </Button>
      </Stack>
    </Stack>
  );
}

export default CompanyInfoComp;
