import React, { useEffect } from "react";
import SignUpComp from "../singUpForm";
import Button from "../Button";
import CommonDialog from "../dialog/CommonDialog";
import ThankYou from "./ThankYou";

function SignUpCommonDialog({
  children,
  title,
  className,
  callback,
  defaultOpen=false,
}) {
  const [signupModal, setSignupModal] = React.useState(false);
  const [thankYouDialog, setThankYouDialog] = React.useState(false);
  const [thankYouData, setThankYouData] = React.useState();

  useEffect(() => {
    if (defaultOpen) setSignupModal(true);
  }, [defaultOpen]);

  return (
    <>
      {signupModal && (
        <SignUpComp
          open={signupModal}
          thankYouOpen={(data) => {
            setThankYouData(data);
            setThankYouDialog(true);
          }}
          handleClose={() => {
            callback?.();
            setSignupModal(false);
          }}
        />
      )}
      <Button
        buttonText={title}
        className={className}
        handleClick={() => {
          setSignupModal(true);
        }}
      >
        {children}
      </Button>
      {thankYouDialog && (
        <CommonDialog
          open={thankYouDialog}
          handleClose={() => setThankYouDialog(false)}
          title={"Thankyou"}
          className="thankyou-popup"
        >
          <ThankYou data={thankYouData} />
        </CommonDialog>
      )}
    </>
  );
}

export default SignUpCommonDialog;
