import { Typography } from "@mui/material";
import React from "react";
import CheckIcon from "../../assets/images/check-mark-icon.png";

function ThankYouDemoDialog() {
  return (
    <div className="thankyou-popup">
      <div className="thankyou-check-icon">
        <img src={CheckIcon} alt="" width={"100px"} height={"100px"} />
      </div>
      <Typography variant="h2" mb={2}>
        Thank You!
      </Typography>
      <Typography variant="body1" mb={2.5}>
        We’ve received your request to explore Taskboard! and an email with
        detailed instructions on how to access the demo will be sent to you
        shortly.
      </Typography>
      <Typography variant="body1" mb={2}>
        If you have any questions or need assistance, feel free to reach out to
        us at support@taskboard.dev. We’re excited for you to try Taskboardand
        see how it can simplify task management!
      </Typography>
     
    </div>
  );
}

export default ThankYouDemoDialog;
